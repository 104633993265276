import * as echarts from 'echarts';

const componentChart = () => {

  const neukredite = document.getElementById('neukredite')
  if (neukredite) {
    var echartNeukredite = echarts.init(neukredite);
    echartNeukredite.setOption({
      title: {
        text: 'Neukreditvergabe in Millionen Euro',
        bottom: 0,
        right: '10%'
      },
      tooltip: {},
      xAxis: {
      data: ['2020', '2021', '2023']
      },
      yAxis: {},
      series: [
        {
          name: 'Neukredite',
          type: 'bar',
          itemStyle: {
            borderRadius: [50, 50, 0, 0],
          },
          barWidth: "50%",
          data: [
            {
              value: 575,
              itemStyle: {
                color: 'rgba(100, 155, 215, 1)'
              }
            },
            {
              value: 671,
              itemStyle: {
                color: 'rgba(50, 125, 200, 1)'
              }
            },
            {
              value: 773,
              itemStyle: {
                color: 'rgba(255, 102, 0, 1)'
              }
            },
          ],
          padding: 5
        }
      ]
    });
  }

  const zinsueberschuss = document.getElementById('zinsueberschuss')
  if (zinsueberschuss) {
    var echartZinsueberschuss = echarts.init(zinsueberschuss);
    echartZinsueberschuss.setOption({
      title: {
        text: 'Zinsüberschuss in Millionen Euro',
        bottom: 0,
        right: '10%'
      },
      tooltip: {},
      xAxis: {},
      yAxis: {
        data: ['2020', '2021', '2022'],
        inverse: true
      },
      series: [
        {
          name: 'Zinsüberschuss',
          type: 'bar',
          itemStyle: {
            borderRadius: [0, 50, 50, 0],
          },
          barWidth: "50%",
          data: [
            {
              value: 54.4,
              itemStyle: {
                color: 'rgba(100, 155, 215, 1)'
              }
            },
            {
              value: 53.9,
              itemStyle: {
                color: 'rgba(50, 125, 200, 1)'
              }
            },
            {
              value: 54.2,
              itemStyle: {
                color: 'rgba(255, 102, 0, 1)'
              }
            },
          ],
          padding: 5
        }
      ]
    });
  }

  if (zinsueberschuss && neukredite) {
    window.onresize = function () {
      echartNeukredite.resize();
      echartZinsueberschuss.resize();
    };
  }

  // const chart1 = document.getElementById('chart1')
  // if (chart1) {
  //   var myChart1 = echarts.init(chart1);
  //   myChart1.setOption({
  //     title: {
  //       text: 'Bar'
  //     },
  //     tooltip: {},
  //     xAxis: {
  //       data: ['shirt', 'cardigan', 'chiffon', 'pants', 'heels', 'socks']
  //     },
  //     yAxis: {},
  //     // grid: {
  //     // 	left: '5%'
  //     // } ,
  //     series: [
  //       {
  //         name: 'sales',
  //         type: 'bar',
  //         itemStyle: {
  //           borderRadius: [50, 50, 0, 0],
  //         },
  //         data: [5, 20, 36, 10, 10, 20],
  //         padding: 5
  //       }
  //     ]
  //   });
  // }

  // const chart2 = document.getElementById('chart2')
  // if (chart2) {
  //   var myChart2 = echarts.init(document.getElementById('chart2'));
  //   myChart2.setOption({
  //     title: {
  //       text: 'Bar Horozintal'
  //     },
  //     tooltip: {},
  //     xAxis: {},

  //     yAxis: {
  //       data: ['shirt', 'cardigan', 'chiffon', 'pants', 'heels', 'socks']
  //     },
  //     series: [
  //       {
  //         name: 'sales',
  //         type: 'bar',
  //         itemStyle: {
  //           borderRadius: [0, 50, 50, 0],
  //         },
  //         data: [
  //           5,
  //           {
  //             value: 20,
  //             itemStyle: {
  //               color: 'rgba(0, 150, 136, 1)'
  //             }
  //           },
  //           36,
  //           10,
  //           10,
  //           20
  //         ],
  //       }
  //     ],
  //   });
  // }

  // const chart3 = document.getElementById('chart3')
  // if (chart3) {
  //   var myChart3 = echarts.init(document.getElementById('chart3'));
  //   myChart3.setOption({
  //     title: {
  //       text: 'Bar Horozintal'
  //     },
  //     tooltip: {},
  //     xAxis: {
  //       type: 'category',
  //       data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  //     },
  //     yAxis: {
  //       type: 'value'
  //     },
  //     series: [
  //       {
  //         data: [150, 230, 224, 218, 135, 147, 260],
  //         type: 'line',
  //         color: '#f60'
  //       },
  //       {
  //         data: [200, 250, 200, 100, 150, 180, 200],
  //         type: 'line'
  //       },
  //     ]
  //   });
  // }

  // const chart4 = document.getElementById('chart4')
  // if (chart4) {
  //   var myChart4 = echarts.init(document.getElementById('chart4'));
  //   myChart4.setOption({
  //     tooltip: {
  //       trigger: 'item'
  //     },
  //     legend: {
  //       top: '5%',
  //       left: 'center'
  //     },
  //     series: [
  //       {
  //         name: 'Access From',
  //         type: 'pie',
  //         radius: ['50%', '70%'],
  //         avoidLabelOverlap: false,
  //         label: {
  //           show: false,
  //           position: 'center'
  //         },
  //         // emphasis: {
  //         // 	label: {
  //         // 		show: true,
  //         // 		fontSize: '40',
  //         // 		fontWeight: 'bold'
  //         // 	}
  //         // },
  //         labelLine: {
  //           show: false
  //         },
  //         data: [
  //           { value: 50, itemStyle: { color: 'rgba(0, 150, 136, 1)' }, name: 'Search Engine' },
  //           { value: 25, name: 'Direct' },
  //           { value: 25, name: 'Email' }
  //         ]
  //       }
  //     ]
  //   });
  // }

};

export default componentChart;

